
        import * as module0 from './controllers/clipboard_controller.js';import * as module1 from './controllers/example_controller.js';import * as module2 from './controllers/scroll_spy_controller.js';import * as module3 from './controllers/search_controller.js';import * as module4 from './controllers/side_nav_controller.js';import * as module5 from './controllers/theme_switcher_controller.js'
        const modules = {
            "./controllers/clipboard_controller.js": module0,
            "./controllers/example_controller.js": module1,
            "./controllers/scroll_spy_controller.js": module2,
            "./controllers/search_controller.js": module3,
            "./controllers/side_nav_controller.js": module4,
            "./controllers/theme_switcher_controller.js": module5,
        };
        export default modules;
      